import {
  forwardRef,
  ForwardedRef,
  useEffect,
  useRef,
  useState,
  useImperativeHandle,
  Ref,
} from 'react';
import DPlayer from 'dplayer';
import type { DPlayerOptions } from 'dplayer';

interface IProps extends DPlayerOptions {
  src?: string;
}

// https://dplayer.diygod.dev/zh/guide.html#special-thanks

function BasicVideo(props: IProps, ref: ForwardedRef<DPlayer | null>) {
  const { src, ...extra } = props;
  const elRef = useRef<HTMLDivElement | null>(null);
  const [player, setPlayer] = useState<DPlayer | null>(null);

  useImperativeHandle(ref as Ref<DPlayer | null>, () => player, [player]);

  useEffect(() => {
    let dp: DPlayer | null = null;
    if (elRef.current && src) {
      const player = new DPlayer({
        ...extra,
        container: elRef.current,
        screenshot: true,
        hotkey: true,
        lang: 'zh-cn',
        video: {
          url: src,
        },
        contextmenu: [],
      });
      dp = player;
      setPlayer(player);
    }
    return () => {
      dp?.destroy();
    };
  }, []);

  return <div ref={elRef}></div>;
}

export const Video = forwardRef(BasicVideo);
