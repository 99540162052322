import { useMemo, useState } from 'react';
import { useBoolean, useMemoizedFn } from 'ahooks';
import { Input, DatePicker, Select } from '@formily/antd-v5';
import { Button, Card, Modal, UploadFile, message } from 'antd';
import { AiOutlineDelete } from 'react-icons/ai';
import { useTranslation } from 'react-i18next';
import { createFiles, deleteFiles, getFilesList } from 'src/api/clients/system';
import { IFilesInfo, IGetFilesListReq } from 'src/api/types/system';
import { UploadFileModal } from 'src/components/UploadFile';
import { QueryTable, QueryTableColumnProps } from 'src/components/QueryTable';
import { schema, columns } from './schema';
import { omit } from 'lodash-es';
import { saveAs } from 'file-saver';
import { Video } from 'src/components/Video';
import ReactAudioPlayer from 'react-audio-player';

const SystemFilesTableName = 'SystemFilesTableName';

export default function SystemFiles() {
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [visible, { setTrue: setVisibleTrue, setFalse: setVisibleFalse }] = useBoolean(false);
  const [mediaInfo, setMediaInfo] = useState<{ type: string; url: string }>();
  const [open, { setTrue: setOpen, setFalse: setClose }] = useBoolean(false);
  const [loading, { setTrue: setLoadingTrue, setFalse: setLoadingFalse }] = useBoolean(false);
  const { t } = useTranslation();
  const getFilesTableList = useMemoizedFn(async (params: IGetFilesListReq) => {
    const result = await getFilesList({
      ...params,
    });
    return {
      data: result.rows,
      total: result.count,
    };
  });

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    console.log('`  `newSelectedRowKeys: ', newSelectedRowKeys);
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const onDeleteDataByIds = useMemoizedFn(async (ids: string[]) => {
    Modal.confirm({
      title: '注意',
      content: `删除后无法恢复，确定删除指定文件吗？`,
      okText: '确定',
      cancelText: '取消',
      onOk: async () => {
        await deleteFiles(ids);
        message.success('删除成功');
        onRefresh();
      },
    });
  });

  const onRefresh = useMemoizedFn((payload: Record<string, any> = {}) => {
    QueryTable.ProviderGlobalDispatch(
      {
        type: QueryTable.ActionEnum.REQUEST_START,
        payload,
      },
      SystemFilesTableName,
    );
  });

  const onSubmit = async (files: UploadFile[]) => {
    if (!files.length) {
      message.success('请选择上传文件');
      return;
    }
    setLoadingTrue();
    try {
      const createList = files.map(async (file) =>
        createFiles({
          ...omit(file.response, ['hash', 'key']),
        }),
      );
      await Promise.all(createList);
      message.success('上传成功');
      onRefresh();
    } catch (error) {
      console.log('error: ', error);
    } finally {
      setLoadingFalse();
    }
  };

  const openMedia = useMemoizedFn((type: string, url: string) => {
    setMediaInfo({ type, url });
    setVisibleTrue();
  });

  const totalColumn = useMemo(() => {
    return [
      ...columns(openMedia),
      {
        title: '操作',
        fixed: 'right',
        width: 150,
        renderType: 'Operation',
        renderProps: (col: IFilesInfo) => {
          return {
            buttons: [
              {
                label: '下载',
                onClick: async () => {
                  if (!col.url) return message.success('下载失败，请刷新重试');
                  saveAs(col.url, col.fileName);
                },
              },
              {
                label: '删除',
                onClick: async () => {
                  if (!col.fileId) return message.success('删除失败，请刷新重试');
                  await onDeleteDataByIds([col.fileId]);
                },
              },
            ],
          };
        },
      },
    ] as QueryTableColumnProps;
  }, [columns]);

  return (
    <Card className="m-4 rounded-md">
      <div className="text-base font-semibold mb-4">{t('menu.files')}</div>
      <QueryTable.Provider name={SystemFilesTableName} requestFn={getFilesTableList}>
        <QueryTable.Filter schema={schema} components={{ Input, Select, DatePicker }} />
        <QueryTable.Table
          columns={totalColumn}
          rowKey="fileId"
          scroll={{ x: 1200 }}
          rowSelection={{
            selectedRowKeys,
            onChange: onSelectChange,
          }}
          tabsNode={
            <Button type="primary" onClick={setOpen}>
              上传文件
            </Button>
          }
          extraRightTool={
            <Button
              disabled={!selectedRowKeys.length}
              color="danger"
              icon={<AiOutlineDelete />}
              onClick={() => {
                onDeleteDataByIds(selectedRowKeys as string[]);
              }}
              variant="outlined">
              批量删除
            </Button>
          }
        />
      </QueryTable.Provider>
      <UploadFileModal
        open={open}
        onCancel={setClose}
        onSubmit={onSubmit}
        confirmLoading={loading}
      />
      <Modal
        width={mediaInfo?.type === 'video' ? 690 : 350}
        open={visible}
        onCancel={() => {
          setVisibleFalse();
        }}
        destroyOnClose
        title={`${mediaInfo?.type === 'video' ? '视频' : '音频'}预览`}
        footer={null}>
        {mediaInfo?.type === 'video' ? (
          <div onContextMenu={(e) => e.preventDefault()}>
            <Video src={mediaInfo.url} />
          </div>
        ) : (
          <ReactAudioPlayer src={mediaInfo?.url} controls autoPlay={visible} />
        )}
      </Modal>
    </Card>
  );
}
